import { usePublisherContext } from '@concepts/Publisher/store/context'
import { isStackSocial } from '@concepts/Publisher/namedPublisher'

type Seo = {
  favicon: string
  publisherTitle: string
  description: string
  hostname: string
  noIndex: boolean
  isStackSocial: boolean
  fbId?: string
}

const useSeo = (): Seo => {
  const {
    favicon: faviconPublisher,
    code,
    name,
    platformName,
    noIndex,
    hostname,
    vertical = '',
    fbPixelId
  } = usePublisherContext()

  const publisherTitle = name || ''
  const favicon = faviconPublisher?.url || ''

  const description: Record<string, string> = {
    lifestyle: `${platformName} is the leading destination for stylish accessories, gadgets, everyday carry essentials & more. Discover one-of-a-kind products at amazing prices.`,
    womens: `${platformName}: Exclusive Deals, Delivered Daily. Shop trending beauty, home & style essentials at the web’s best prices.`,
    academy: `${platformName} partners with top-rated online instructors to deliver thousands of affordable courses on coding, design, investing, marketing, business & much, much more. Learn anytime at home or on-the-go.`,
    default: `${platformName} scours the web for the newest software, gadgets & web services. Explore our giveaways, bundles, Pay What You Want deals & more.`
  }

  return {
    favicon,
    publisherTitle,
    description: description[vertical] || description.default,
    hostname: hostname as string,
    noIndex: Boolean(noIndex),
    isStackSocial: isStackSocial(code || ''),
    fbId: fbPixelId as string
  }
}

export default useSeo
