import Link from './Link'
import type { LinkProps, Path } from './Link'

type UseLinkProps = LinkProps & {
  useLink: boolean
  children: React.ReactNode
  className?: string
  to?: Path
}

const UseLink = ({ useLink, children, ...props }: UseLinkProps) => {
  return useLink ? <Link {...props}>{children}</Link> : <>{children}</>
}

export default UseLink
